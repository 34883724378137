<template>
  <div class="clearfix">
    <div class="container my-5">
      <div class="row">
        <div class="col-md-12">
          <br>
          <br>
          <h2 class="text-dark">Descarga los siguientes documentos:</h2>
          <br>
          <br>
          <b-button href="/docs/NTC-2050.pdf">NTC 2050</b-button>
          <br>
          <br>
          <b-button href="/docs/RES180540_2010-RETILAP.pdf">RESOLUCIÓN 180540 DE 2010 RETILAP</b-button>
          <br>
          <br>
          <b-button href="/docs/Resolucion-ONAC-17-OIN-056.pdf">RESOLUCION ONAC 17-OIN-056</b-button>
          <br>
          <br>
          <b-button href="/docs/RETIE-2013.pdf">RETIE 2013</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Documentos',
  mounted () {
    this.$store.commit('setActualPage', 'Documentos de Consulta')
  }
}
</script>

<style>
</style>
